import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { Invoice } from 'shared/models/invoice/invoice.model';
import { ResultSet } from 'shared/models/result-set.model';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';

export const SET_INVOICE_SEARCH_CRITERIA = 'SET_INVOICE_SEARCH_CRITERIA';
export const SET_INVOICE_SEARCH_ERROR = 'SET_INVOICE_SEARCH_ERROR';
export const RESET_INVOICE_SEARCH_CRITERIA = 'RESET_INVOICE_SEARCH_CRITERIA';
export const SET_INVOICE_SEARCH_PAGINATION = 'SET_INVOICE_SEARCH_PAGINATION';
export const SET_INVOICE_SEARCH_SORTING = 'SET_INVOICE_SEARCH_SORTING';
export const SET_INVOICE_SEARCH_RESULTS = 'SET_INVOICE_SEARCH_RESULTS';
export const RESET_INVOICE_SEARCH_RESULTS = 'RESET_INVOICE_SEARCH_RESULTS';

export const FETCH_INVOICES = `${REQUEST_START}:FETCH_INVOICES`;
export const FETCH_INVOICES_SUCCESS = `${REQUEST_SUCCESS}:FETCH_INVOICES`;
export const FETCH_INVOICES_FAILURE = `${REQUEST_FAILURE}:FETCH_INVOICES`;

export const setInvoiceSearchCriteria = (criteria: ManageInvoicesSearchCriteria) => ({type: SET_INVOICE_SEARCH_CRITERIA, criteria});
export const setInvoiceSearchPagination = (criteria: PageableRequest) => ({type: SET_INVOICE_SEARCH_PAGINATION, criteria});
export const setInvoiceSearchSorting = (criteria: SortableRequest) => ({type: SET_INVOICE_SEARCH_SORTING, criteria});
export const setInvoiceSearchError = (error: string) => ({type: SET_INVOICE_SEARCH_ERROR, error});

export const setInvoiceSearchResults = (results: ResultSet<Invoice>) => ({type: SET_INVOICE_SEARCH_RESULTS, results});
export const resetInvoiceSearchResults = () => ({type: RESET_INVOICE_SEARCH_RESULTS});

export const fetchInvoices = (criteria: ManageInvoicesSearchCriteria & SortableRequest & PageableRequest) => ({type: FETCH_INVOICES, criteria});
export const fetchInvoicesSuccess = (results: ResultSet<Invoice>) => ({type: FETCH_INVOICES_SUCCESS, results});
export const fetchInvoicesFailure = (error: AjaxError) => ({type: FETCH_INVOICES_FAILURE, error});
